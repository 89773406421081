<template>
  <div>
    <v-skeleton-loader
      type="card"
    ></v-skeleton-loader>
    <v-skeleton-loader
      v-for="n in 10"
      :key="n"
      class="mx-auto"
      type="list-item"
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: 'LoadingEndpointDetail',
  components: {},
  data: () => ({
  }),
  methods: {
    test: function () {}
  }
}
</script>
